.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content {
  overflow-x: auto;
}

.navbar.is-primary {
  background-color: #031942 !important;
  color: white !important;
}

a.navbar-link {
  background-color: #FA4515 !important;
}

body a[href^="/app"] {
  background-color: #031942 !important;
  color: #FA4515 !important;
}

.navbar-item.is-active {
  color: #031942 !important;
  background-color: #FA4515 !important;
}

a.navbar-link:hover,
body a[href^="/app"]:hover,
.navbar-item:hover {
  background-color: #FA4515 !important;
  color: #031942 !important;
}

/* Always display the burger icon */
@media screen and (min-width: 769px) {
  .navbar-burger {
    display: block;
  }
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 50px; /* Ensure margin for visibility */
}

@media screen and (max-width: 768px) {
  .table-wrapper table thead {
    display: none;
  }

  .table-wrapper table tbody,
  .table-wrapper table tbody tr,
  .table-wrapper table tbody tr td {
    display: block;
    width: 100%;
  }

  .table-wrapper table tbody tr {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px; /* Ensure padding to make the last row visible */
  }

  .table-wrapper table tbody tr:last-child {
    margin-bottom: 30px; /* Extra margin for the last row to ensure full visibility */
  }

  .table-wrapper table tbody tr td {
    text-align: right;
    padding-left: 50%;
    position: relative;
    overflow: hidden;
    white-space: pre-wrap; /* Allow text wrapping */
    word-wrap: break-word; /* Ensure long words break properly */
  }

  .table-wrapper table tbody tr td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 50%;
    text-align: left;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-wrapper table tbody tr td.empty-comment::before {
    content: "Comment by Admin"; /* Ensure label for empty comments */
  }

  .table-header-controls {
    display: flex;
    flex-direction: column;
  }

  .calendar-container {
    display: flex;
    justify-content: center;
  }

  .buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .buttons .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .floor-dropdown {
    margin-bottom: 20px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-dropdown {
  max-height: 30em;
  overflow: auto;
}

.dropdown {
  line-height: 1.1;
}

.react-calendar.hide {
  display: none;
}

.column {
  padding: 0px;
}

.accordion-line-separator {
  width: 100%;
  border-top: 1px solid #ddd; /* Adjust color and thickness as needed */
  margin: 10px 0; /* Adjust spacing as needed */
}


.card {
  margin-bottom: 5px;
  width: 100%;
}

.card > .card-header {
  cursor: pointer;
}

.card > .card-header > .icon {
  transform: rotate(180deg);
  transition: transform 150ms ease-out;
}

.card > .card-content {
  transition: max-height 250ms ease;
  overflow: hidden;
}

.card-header-title-min,
.card-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-header-desk-titles {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.card-header-desk-titles .desk-title {
  flex: 0 1 100px; /* Allow desk titles to grow and shrink */
  height: 40px; /* Ensure desk titles have a consistent height */
  margin-bottom: 10px; /* Add some margin at the bottom for spacing */
  padding: 10px; /* Add some padding for visual spacing */
  border: 1px solid #ddd; /* Add a border for better visual separation */
  background-color: #f5f5f5; /* Add a background color for better visual separation */
  text-align: center; /* Center the text within each desk title */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  position: relative; /* Ensure the tooltip appears correctly */
  z-index: 1; /* Ensure desk titles are above other content */
  border-radius: 4px; /* Rounded corners for a nicer look */
}



.card-header-desk-titles .desk-title {
  flex: 1 1 auto; /* Allow desk titles to grow and shrink */
  min-width: 100px; /* Ensure desk titles have a minimum width */
  margin-bottom: 5px; /* Add some margin at the bottom for spacing */
  padding: 5px; /* Add some padding for visual spacing */
  border: 1px solid #ddd; /* Optional: Add a border for better visual separation */
  background-color: #f5f5f5; /* Optional: Add a background color for better visual separation */
  text-align: center; /* Center the text within each desk title */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  position: relative; /* Ensure the tooltip appears correctly */
  z-index: 1; /* Ensure desk titles are above other content */
}

.tooltip-popup {
  position: fixed;
  z-index: 10000 !important;
  background-color: white;
  border: 1px solid black;
  padding: 5px;
  pointer-events: none;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip[data-tooltip]:hover .tooltip-popup {
  visibility: visible;
  opacity: 1;
}



@media screen and (min-width: 320px) {
  .tooltip[data-tooltip]:hover::after {
    /* Logic to detect and correct overflow */
    left: 50%;
    transform: translateX(-50%);
    if (tooltipRect.left < 0) {
      left: 0;
      transform: none;
    }
  }
}



@media screen and (min-width: 768px) {
  .card-header-title-min {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .card-header-desk-titles {
    flex: 1;
  }
}

.card-header-title1 {
  width: 150px;
}

.card-header-title > Button {
  margin-right: 15px;
}

.card.not-expanded > .card-header > .icon {
  transform: rotate(0deg);
}

.card.not-expanded > .card-content {
  padding: 0;
  max-height: 0px;
}

.section {
  padding: 0rem 3rem;
}

tr.active {
  font-weight: bold;
}

.line-break {
  white-space: pre-line;
}

.dark-grey {
  color: darkgrey;
}

.tooltip {
    padding: 10px; /* Add padding to the tooltip */
    margin: 5px; /* Add margin to create space around the tooltip */
    border-radius: 4px; /* Optional: Add rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visibility */
}

.floor-plan-container {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: visible; /* Ensures no overflow if the image is larger than the container */
  z-index: 1;
}

.floor-plan-container img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%; /* Ensures the image scales down to fit within the container */
  max-height: 100vh; /* Ensures the image does not exceed the viewport height */
  object-fit: contain; /* Maintains the aspect ratio */
}

.area {
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
}

.react-confirm-alert-overlay {
  z-index: 10000 !important;
}

.react-confirm-alert {
  z-index: 10001 !important;
}

.react-confirm-alert-body {
  position: relative;
  z-index: 10002 !important;
}

.area.free {
  border: 3px solid green;
}

.area.booked {
  border: 3px solid red;
}

.area.booked.own {
  border: 4px solid blue;
}

.area.booked.admin {
  border: 4px solid magenta;
}

.area.selected {
  border: 3px solid orange !important;
}

.is-blue {
  background-color: blue;
  color: blue;
  border: none;
}

.is-orange {
  background-color: orange;
  color: orange;
  border: none;
}


.long-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.booking-form-container {
  background-color: #bfceeb;
  padding: 20px; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.modal.is-active {
  z-index: 1050;
}

.modal-background {
  z-index: 1040;
}

.modal-card {
  z-index: 1050;
}

h1 {
  font-size: 2em !important;
  font-weight: bold !important;
  color: #031942 !important; 
}


.button-custom {
  background-color: #bfceeb !important; 
  color: #000000 !important; 
  border: none; 
  padding: 10px 20px; 
   font-size: 2em;
  font-weight: bold; 
  border-radius: 4px; 
  cursor: pointer; 
}

 .form.is-loading {
    position: relative;
  }

  .form.is-loading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
  }

  .form.is-loading::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 0.25em solid #dbdbdb;
    border-top-color: #3273dc;
    animation: spinAround 500ms infinite linear;
    z-index: 2;
  }

  @keyframes spinAround {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
  }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.overlay p {
  color: white;
  margin-top: 10px;
  font-size: 18px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add these rules at the end of your file */

.tooltip.custom-tooltip::before {
    position: absolute;
    content: attr(data-tooltip);
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.75rem;
    max-width: 300px;
    color: #fff;
    width: max-content;
}

.tooltip.custom-tooltip.is-tooltip-active::before {
    opacity: 1;
    visibility: visible;
}

.tooltip.custom-tooltip::before {
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip.custom-tooltip.tooltip-green::before {
    background: rgba(0, 128, 0, 0.9);
}

.tooltip.custom-tooltip.tooltip-red::before {
    background: rgba(255, 0, 0, 0.9);
}

.tooltip.custom-tooltip.tooltip-blue::before {
    background: rgba(0, 0, 255, 0.9);
}

.tooltip.custom-tooltip.tooltip-magenta::before {
    background: rgba(255, 0, 255, 0.9);
}

.tooltip.custom-tooltip.tooltip-black::before {
    background: rgba(0, 0, 0, 0.9);
}

.no-color-change {
  color: inherit; /* Use the inherited color */
}

.no-color-change:visited {
  color: inherit; /* Keep the same color for visited links */
}

.no-color-change:hover {
  color: #FA4515 !important; /* Keep the hover color */
  background-color: #031942 !important; /* Keep the background color on hover */
}

.no-color-change:focus {
  color: #FA4515 !important; /* Keep the color when focused */
  background-color: #031942 !important; /* Keep the background color when focused */
}

.no-color-change:active {
  color: #ffffff !important; /* Keep the color when active */
  background-color: #031942 !important; /* Keep the background color when active */
}